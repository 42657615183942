import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; 2023 Bix Pettus</p>
    </footer>
    
  );

}

export default Footer;

//Prompt Craft AI