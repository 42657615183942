// create a react component that inputs a text area message then preforms a fetch request to local host 3001 gets back a response as a data.message and displays message in box below
import React, { useState } from 'react';
import './App.css';
import './styles.css';
import Footer from './Footer';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Helmet } from 'react-helmet';

//front end tingz
//get outta my ends



function App() {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:3001/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',      
      },
      body: JSON.stringify({ message }),
    })
      .then((res) => res.json())
      .then((data) => setResponse(data.message));
  };

    const isSubmitDisabled = message.length < 15 || message.length > 30;



  return (
    <div className="App">
      <Helmet>
        <title>Prompt Enhancer</title>
        <meta 
          name="Description"
          content="A tool to assist with AI prompts"
        />
        <meta name="keywords" content="AI, Prompt, Prompt Enhancer, ChatGPT, Prompt Upscale, AI Generated Art, Bix Pettus"/>
      </Helmet>
      <h1 className="title">PROMPT ENHANCER</h1>
      
      <a href="https://www.buymeacoffee.com/Bixpettus" target="_blank" className="coffee-button">
        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" />
      </a>
      <form onSubmit={handleSubmit}>
        <textarea
          value={message}
          placeholder="Write your prompt to be Enhanced"
          onChange={(e) => setMessage(e.target.value)}
          minLength="15"
          maxLength="30"
        ></textarea>
         <button type="submit" className="submit-button" disabled={isSubmitDisabled}>
           Submit
        </button>
      </form>
      <div>
        {response && <div className="response"><b>AI:</b> {response} </div>}
      </div>
      <div className="container">
        <div className="triangle"></div>
        <div className="circle"></div>
        <div className="square"></div>
        
        <div class="orb"></div>
      </div>
        <div className="textbox">
          <h2>Getting started</h2>
          <p>The Prompt Enhancer turns a short bland prompt into a detailed paragraph for an AI image generator to understand better. Write your prompt in the textbox to get started.</p>
          <p>Click the Coffee button to help support the website and keep it running!</p>
      </div>
      <div className="container3">
      <ReactCompareSlider
        itemOne={<ReactCompareSliderImage src="/before.webp" srcSet="/after.jpg" alt="Image one" />}
        itemTwo={<ReactCompareSliderImage src="/after.jpg" srcSet="/before.webp" alt="Image two" />}
        className="compare-slider"
      />
      <div className="textboxafter">
          <h2>After</h2>
          <p>Now the prompt enhancer has been used and there is an immediate difference in quality and detail.The new prompt looks like this: A photorealistic image of a high defintion white fluffy cat with sparkling blue eyes, leaning on an old wooden fence </p>
      </div>
      <div className="textboxbefore">
          <h2>Before</h2>
          <p>This is what a basic image would look like without the prompt enhancer. The prompt was "A cat on a fence" You can see the cat has wonky eyes and it barely looks like a cat.</p>
      </div>
    </div>
      <Footer>
        .
      </Footer>
    </div>
  );
  
}

export default App

